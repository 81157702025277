:root {
  // --color: rgb(25, 118, 210);
  // --lighterColor: rgb(120, 172, 200);
  --color: rgb(0, 31, 63);
  --orange-color: rgb(240, 82, 44);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.price-box {
  color: white;
  transition: transform ease 300ms;
  background-color: var(--color);
}

.price-box:hover {
  transform: translate(0, -40px);
  background-color: rgba(0, 31, 63, 0.7);
}

a {
  text-decoration: none;
}

form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.bolder-text {
  font-weight: bolder;
  display: block;
  margin: 30px auto;
  text-align: center;
}

.home-icon:hover {
  transform: scale(1.5);
  // color: yellow;
}

ul > li {
  margin: 10px 0px 10px 30px;
}

.wave-bottom {
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background: rgb(0, 31, 63);
  transform: scale(1, 1);
}
.wave-bottom:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: white;
  right: -25%;
  top: 20px;
}

.wave-bottom:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: rgb(0, 31, 63);
  left: -25%;
  top: -240px;
  clip-path: ellipse(100% 15% at -15% 100%);
}

.App {
  overflow: hidden;
}

footer {
  background-color: var(--color);
}

// .box {
//   position: relative;
//   width: 500px;
//   height: 200px;
// }

// .box span {
//   position: absolute;
//   inset: 0;
//   transform: rotate(180deg);
//   left: 50%;
//   right: 50%;
// }

// .box span::before {
//   content: " ";
//   position: absolute;

//   width: 250px;
//   height: 300px;
//   background: url("/off.png");
//   background-size: cover;
//   background-position: center;
//   scale: (0.75);
//   animation: animate 4s steps(1) infinite;
//   animation-delay: calc(0.2s * var(--i));

// }

// @keyframes animate{
//   0%, 100%{
//     background: url(/off.png);

//   }
//   50%{
//     background: url(/on.png) ;

//   }
// }

.contact > span {
  color: var(--color);
  margin-left: 30px;
}

@media only screen and (max-width: 400px) {
  .contact > span {
    margin-left: 0px;
  }
  .contact-container > div > a,
  .contact-container > div > h6,
  .MuiTypography-h6.MuiTypography-root.css-g18fxb-MuiTypography-root {
    font-size: 1rem;
  }
  #page-title {
    font-size: 2.5rem;
  }
}



@media only screen and (max-width: 600px) {
  .css-12j3mlf-MuiListItemIcon-root {
    min-width: 36px !important;
  }
}

.Mui-selected {
  background-color: white !important;
  position: relative;
}

.Mui-selected::after {
  content: "";
  display: block;
  position: absolute;

  bottom: -2px;
  left: 40%;
  width: 20%;
  height: 1px;
  background-color: var(--orange-color);
}

// .page-title {
//   position: relative;
// }

// .page-title::after {
//   content: "";
//   display: block;
//   position: absolute;

//   bottom: 3px;
//   left: 45%;
//   width: 5%;
//   height: 3px;
//   background-color: var(--orange-color);
//   z-index: -1;
// }
